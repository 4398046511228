export const defaultQuestions = {
  en: [
    "How do I access the library section of EduGainer's?",
    "How can I pay the library fee?",
    "What classes can I enroll in at EduGainer's?",
    "How can I access the EduGainer's homepage?",
    "How do I log in to my EduGainer's account?",
    "What is the process to register on the EduGainer's website?",
    "What should I do if I forgot my password?",
    "How can I start chatting with the EduGainer's assistant?",
    "Where can I find the policies of EduGainer's?",
    "What resources are available on the EduGainer's platform?"
  ],
  hi: [
    "मैं EduGainer के पुस्तकालय अनुभाग तक कैसे पहुँच सकता हूँ?",
    "मैं पुस्तकालय शुल्क कैसे चुका सकता हूँ?",
    "मैं EduGainer में कौन से पाठ्यक्रमों में नामांकित हो सकता हूँ?",
    "मैं EduGainer की होमपेज तक कैसे पहुँच सकता हूँ?",
    "मैं अपने EduGainer खाता में कैसे लॉगिन कर सकता हूँ?",
    "EduGainer वेबसाइट पर पंजीकरण की प्रक्रिया क्या है?",
    "अगर मैं अपना पासवर्ड भूल जाऊँ तो मुझे क्या करना चाहिए?",
    "मैं EduGainer के सहायक से चैट कैसे शुरू कर सकता हूँ?",
    "मैं EduGainer की नीतियाँ कहाँ पा सकता हूँ?",
    "EduGainer के मंच पर कौन से संसाधन उपलब्ध हैं?"
  ],
  bn: [
    "আমি কিভাবে EduGainer এর লাইব্রেরি সেকশন অ্যাক্সেস করতে পারি?",
    "আমি কিভাবে লাইব্রেরি ফি দিতে পারি?",
    "আমি EduGainer এ কোন ক্লাসে ভর্তি হতে পারি?",
    "আমি কিভাবে EduGainer এর হোমপেজ অ্যাক্সেস করতে পারি?",
    "আমি কিভাবে আমার EduGainer অ্যাকাউন্টে লগ ইন করতে পারি?",
    "EduGainer ওয়েবসাইটে রেজিস্টার করার প্রক্রিয়া কি?",
    "যদি আমি আমার পাসওয়ার্ড ভুলে যাই, তাহলে আমি কি করতে পারি?",
    "আমি কিভাবে EduGainer এর সহায়কের সাথে চ্যাট শুরু করতে পারি?",
    "আমি কিভাবে EduGainer এর নীতিগুলি খুঁজে পেতে পারি?",
    "EduGainer এর প্ল্যাটফর্মে কোন resources উপলব্ধ?"
  ],
  ta: [
    "நான் EduGainer இன் நூலக பிரிவை எவ்வாறு அணுக முடியும்?",
    "நான் நூலகக் கட்டணத்தை எவ்வாறு செலுத்த முடியும்?",
    "நான் EduGainer இல் எந்த வகுப்புகளில் சேர முடியும்?",
    "நான் EduGainer இன் முகப்பு பக்கத்தை எவ்வாறு அணுக முடியும்?",
    "நான் என் EduGainer கணக்கில் எவ்வாறு உள்நுழைய முடியும்?",
    "EduGainer இணையதளத்தில் பதிவு செய்யும் செயல்முறை என்ன?",
    "நான் என் கடவுச்சொல்லை மறந்துவிட்டால் என்ன செய்ய வேண்டும்?",
    "நான் EduGainer உதவியுடன் பேச ஆரம்பிப்பது எப்படி?",
    "EduGainer இன் கொள்கைகள் எங்கு கிடைக்கின்றன?",
    "EduGainer இன் மேடை மீது என்னவென்ன வளங்கள் உள்ளன?"
  ],
  mr: [
    "मी EduGainer च्या लायब्ररी विभागात कसा प्रवेश करू शकतो?",
    "मी लायब्ररी फी कशी भरू शकतो?",
    "मी EduGainer मध्ये कोणत्या वर्गात प्रवेश घेऊ शकतो?",
    "मी EduGainer च्या होमपेजला कसा प्रवेश करू शकतो?",
    "मी माझ्या EduGainer खात्यात कसा लॉग इन करू शकतो?",
    "EduGainer वेबसाइटवर नोंदणी प्रक्रिया काय आहे?",
    "जर मी पासवर्ड विसरलो असं तर काय करावं?",
    "मी EduGainer सहायकाशी कसा चॅट सुरू करू शकतो?",
    "EduGainer च्या धोरणांना कुठे पाहू शकतो?",
    "EduGainer च्या प्लॅटफॉर्मवर कोणते संसाधने उपलब्ध आहेत?"
  ],
  gu: [
    "હું EduGainer ની લાઇબ્રેરી વિભાગમાં કેવી રીતે પ્રવેશ કરી શકું?",
    "હું લાઇબ્રેરી ફી કેવી રીતે ચૂકવી શકું?",
    "હું EduGainer માં કયા વર્ગોમાં દાખલ થાઈ શકું?",
    "હું EduGainer ની હોમપેજ કેવી રીતે ઍક્સેસ કરી શકું?",
    "હું મારી EduGainer એકાઉન્ટમાં કેવી રીતે લોગિન કરી શકું?",
    "EduGainer વેબસાઇટ પર રજિસ્ટ્રેશન પ્રક્રિયા શું છે?",
    "જો મેં પાસવર્ડ ભૂલી ગયો હોય તો હું શું કરું?",
    "હું EduGainer ના સહાયક સાથે ચેટ કેવી રીતે શરૂ કરી શકું?",
    "હું EduGainer ની નીતિઓ ક્યાં જોઈ શકું?",
    "હું EduGainer ના પ્લેટફોર્મ પર કયા સંસાધનો ઉપલબ્ધ છે?"
  ],
  pa: [
    "ਮੈਂ EduGainer ਦੇ ਪੁਸਤਕਾਲਾ ਭਾਗ ਨੂੰ ਕਿਵੇਂ ਐਕਸੈਸ ਕਰ ਸਕਦਾ ਹਾਂ?",
    "ਮੈਂ ਪੁਸਤਕਾਲਾ ਫੀਸ ਕਿਵੇਂ ਅਦਾ ਕਰ ਸਕਦਾ ਹਾਂ?",
    "ਮੈਂ EduGainer ਵਿੱਚ ਕਿਹੜੇ ਕਲਾਸਾਂ ਵਿੱਚ ਦਾਖਲ ਹੋ ਸਕਦਾ ਹਾਂ?",
    "ਮੈਂ EduGainer ਦਾ ਹੋਮਪੇਜ ਕਿਵੇਂ ਐਕਸੈਸ ਕਰ ਸਕਦਾ ਹਾਂ?",
    "ਮੈਂ ਆਪਣੇ EduGainer ਖਾਤੇ ਵਿੱਚ ਕਿਵੇਂ ਲੌਗਇਨ ਕਰ ਸਕਦਾ ਹਾਂ?",
    "EduGainer ਵੈਬਸਾਈਟ 'ਤੇ ਰਜਿਸਟਰ ਕਰਨ ਦੀ ਪ੍ਰਕਿਰਿਆ ਕੀ ਹੈ?",
    "ਜੇ ਮੈਂ ਆਪਣਾ ਪਾਸਵਰਡ ਭੁੱਲ ਜਾਂਦਾ ਹਾਂ ਤਾਂ ਮੈਂ ਕੀ ਕਰਾਂ?",
    "ਮੈਂ EduGainer ਦੇ ਸਹਾਇਕ ਨਾਲ ਗੱਲਬਾਤ ਕਿਵੇਂ ਸ਼ੁਰੂ ਕਰ ਸਕਦਾ ਹਾਂ?",
    "ਮੈਂ EduGainer ਦੀਆਂ ਨੀਤੀਆਂ ਕਿੱਥੇ ਪਾ ਸਕਦਾ ਹਾਂ?",
    "EduGainer ਦੇ ਮੰਚ 'ਤੇ ਕਿਹੜੇ ਸਰੋਤ ਉਪਲਬਧ ਹਨ?"
  ],
  kn: [
    "ನಾನು EduGainer ನ ಪುಸ್ತಕಾಲಯ ವಿಭಾಗವನ್ನು ಹೇಗೆ ಪ್ರವೇಶಿಸಬಹುದು?",
    "ನಾನು ಪುಸ್ತಕಾಲಯ ಶುಲ್ಕವನ್ನು ಹೇಗೆ ಪಾವತಿಸಬಹುದು?",
    "ನಾನು EduGainer ನಲ್ಲಿ ಯಾವ ತರಗತಿಗಳಿಗೆ ಸೇರ್ಪಡೆ ಹೊಂದಬಹುದು?",
    "ನಾನು EduGainer ನ ಹೋಂಪೇಜ್ ಅನ್ನು ಹೇಗೆ ಪ್ರವೇಶಿಸಬಹುದು?",
    "ನಾನು ನನ್ನ EduGainer ಖಾತೆಯಲ್ಲಿ ಹೇಗೆ ಲಾಗಿನ್ ಮಾಡಬಹುದು?",
    "EduGainer ವೆಬ್‌ಸೈಟ್‌ನಲ್ಲಿ ನೋಂದಣಿ ಪ್ರಕ್ರಿಯೆ ಏನು?",
    "ನಾನು ನನ್ನ ಪಾಸ್ವರ್ಡ್ ಮರೆಯಿದರೆ ನಾನು ಏನು ಮಾಡಬೇಕು?",
    "ನಾನು EduGainer ಸಹಾಯಕರೊಂದಿಗೆ ಚಾಟ್ ಮಾಡಲು ಹೇಗೆ ಪ್ರಾರಂಭಿಸಬಹುದು?",
    "ನಾನು EduGainer ನ ನೀತಿಗಳನ್ನು ಎಲ್ಲಿ ಕಾಣಬಹುದು?",
    "EduGainer ವೇದಿಕೆಯಲ್ಲಿ ಯಾವ ಸಂಪನ್ಮೂಲಗಳು ಲಭ್ಯವಿವೆ?"
  ],
  ml: [
    "ഞാൻ EduGainerന്റെ ലൈബ്രറി സെക്ഷൻ എങ്ങനെ ആക്‌സസ് ചെയ്യാമെന്ന്?",
    "ഞാൻ ലൈബ്രറി ഫീ എങ്ങനെ അടയ്ക്കാമെന്ന്?",
    "ഞാൻ EduGainerൽ ഏത് ക്ലാസുകളിൽ ചേർന്നു പഠിക്കാമെന്ന്?",
    "ഞാൻ EduGainerന്റെ ഹോംപേജ് എങ്ങനെ ആക്‌സസ് ചെയ്യാമെന്ന്?",
    "ഞാൻ എന്റെ EduGainer അക്കൗണ്ടിൽ എങ്ങനെ ലോഗിൻ ചെയ്യാമെന്ന്?",
    "EduGainer വെബ്സൈറ്റ് രജിസ്ട്രേഷൻ പ്രക്രിയ എന്താണ്?",
    "ഞാൻ എന്റെ പാസ്വേഡ് മറന്നാൽ എനിക്ക് എന്ത് ചെയ്യണം?",
    "ഞാൻ EduGainer സഹായവുമായി ചാറ്റ് എങ്ങനെ ആരംഭിക്കാം?",
    "ഞാൻ EduGainerയുടെ നയങ്ങൾ എവിടെയാണ് കാണാൻ കഴിയുന്നതെന്ന്?",
    "EduGainer പ്ലാറ്റ്ഫോമിൽ എന്തെല്ലാം വിഭവങ്ങൾ ലഭ്യമാണ്?"
  ],
  or: [
    "ମୁଁ EduGainerର ପୁସ୍ତକାଳୟ ଅଞ୍ଚଳକୁ କିପରି ଏକ୍ସେସ୍ କରିପାରିବି?",
    "ମୁଁ ପୁସ୍ତକାଳୟ ଫି କିପରି ଦେୟ କରିପାରିବି?",
    "ମୁଁ EduGainerର କେଉଁ କ୍ଲାସ୍ ଭିତରେ ପଢ଼ିବା ପାଇଁ ଅଂଶଗ୍ରହଣ କରିପାରିବି?",
    "ମୁଁ EduGainerର ହୋମପେଜକୁ କିପରି ଏକ୍ସେସ୍ କରିପାରିବି?",
    "ମୁଁ ମୋ EduGainer ଖାତାରେ କିପରି ଲଗିନ କରିପାରିବି?",
    "EduGainer ୱେବସାଇଟରେ ରେଜିସ୍ଟର ପ୍ରକ୍ରିୟା କଣ?",
    "ଯଦି ମୁଁ ମୋ ପାସୱାର୍ଡ ଭୁଲିଯାଏ, ତେବେ ମୁଁ କଣ କରିବି?",
    "ମୁଁ EduGainer ସହାୟକ ସହିତ ଚାଟ୍ କିପରି ଆରମ୍ଭ କରିପାରିବି?",
    "ମୁଁ EduGainerର ନୀତିଗୁଡିକୁ କେଉଁଠି ପାଇପାରିବି?",
    "EduGainer ମଞ୍ଚରେ କଣ କଣ ସଂସାଧନ ଉପଲବ୍ଧ ଅଛି?"
    ],


  as: [
    "মই EduGainer ৰ লাইব্ৰেৰী অংশ কেনেকৈ এক্সেছ কৰিব পাৰিম?",
    "মই লাইব্ৰেৰী মাচুল কেনেকৈ পৰিশোধ কৰিব পাৰিম?",
    "মই EduGainer ত কিহত ক্লাছত নামভৰ্তি কৰিব পাৰিম?",
    "মই EduGainer ৰ হোমপেজ কেনেকৈ এক্সেছ কৰিব পাৰিম?",
    "মই মোৰ EduGainer একাউণ্টত কেনেকৈ লগইন কৰিব পাৰিম?",
    "EduGainer ৱেবছাইটত ৰেজিষ্টাৰ কৰাৰ প্ৰক্ৰিয়া কি?",
    "যদি মই মোৰ পাছৱৰ্ড পাহৰাই যাওঁ তেন্তে মই কি কৰিব?",
    "মই EduGainer ৰ সহায়কৰ সৈতে চ্যাট কেনেকৈ আৰম্ভ কৰিব পাৰিম?",
    "মই EduGainer ৰ নীতি-কানুন ক'ত পাব পাৰিম?",
    "EduGainer ৰ প্লেটফৰ্মত কি কি সম্পদ উপলব্ধ আছে?"
  ],
  ma: [
    "हम EduGainer के पुस्तकालय अनुभाग तक कइसे पहुँच सकते हैं?",
    "हम पुस्तकालय शुल्क कइसे चुका सकते हैं?",
    "हम EduGainer में कौन से कक्षाओं में नामांकित हो सकते हैं?",
    "हम EduGainer की होमपेज कइसे एक्सेस कर सकते हैं?",
    "हम अपने EduGainer खाते में कइसे लॉगिन कर सकते हैं?",
    "EduGainer वेबसाइट पर रजिस्टर करने की प्रक्रिया क्या है?",
    "अगर हम अपना पासवर्ड भूल जाते हैं तो हमें क्या करना चाहिए?",
    "हम EduGainer के सहायक से चैट कइसे शुरू कर सकते हैं?",
    "हम EduGainer की नीतियां कहां पा सकते हैं?",
    "EduGainer के प्लेटफार्म पर कौन से संसाधन उपलब्ध हैं?"
  ],
  bh: [
    "हम EduGainer के पुस्तकालय सेक्शन तक कइसे पहुँच सकतानी?",
    "हम पुस्तकालय शुल्क कइसे चुकता कर सकतानी?",
    "हम EduGainer में कौन सा क्लास में नाम लिखवा सकतानी?",
    "हम EduGainer के होमपेज कइसे एक्सेस कर सकतानी?",
    "हम अपना EduGainer खाता में कइसे लॉगिन कर सकतानी?",
    "EduGainer वेबसाइट पर रजिस्टर के प्रक्रिया का बा?",
    "अगर हम अपना पासवर्ड भूल जाए त हम का करीं?",
    "हम EduGainer के सहायक से चैट कइसे शुरू कर सकतानी?",
    "हम EduGainer के नीतियाँ कहाँ पाई?",
    "EduGainer के प्लेटफॉर्म पर कौन-कौन संसाधन उपलब्ध बा?"
  ],
  si: [
    "මට EduGainer හි පුස්තකාල අංශයට ගොඩනැඟීමට කෙසේද?",
    "මට පුස්තකාල ගාස්තු ගෙවන්නේ කෙසේද?",
    "මට EduGainer හි කුමන පංති වලට ලියාපදිංචි විය හැකි ද?",
    "මට EduGainer හි මුල් පිටුවට ගොඩනැඟීමට කෙසේද?",
    "මට මගේ EduGainer ගිණුමට කෙසේ ලොග් අයත් විය හැකි ද?",
    "EduGainer වෙබ් අඩවියට ලියාපදිංචි වීමේ ක්‍රියාවලිය කුමක්ද?",
    "ඔබේ මුරපදය අමතක වුවහොත් මට කුමක් කළ යුතුද?",
    "EduGainer හි සහය මාර්ගෝපදේශකය හා කතා බහ ආරම්භ කිරීම කෙසේද?",
    "EduGainer හි ප්‍රතිපත්තියන් සොයාගැනීමට මට කුමක් කළ යුතුද?",
    "EduGainer හි මාර්ගෝපදේශය මත ඇති සම්පත් මොනවාද?"
  ],
  sd: [
    "مانھن EduGainer جي لائبريري سيڪشن تائين ڪيئن رسائي حاصل ڪري سگھان ٿو؟",
    "مانھن لائبريري جو فيس ڪيئن ادا ڪري سگھان ٿو؟",
    "مانھن EduGainer ۾ ڪهڙن ڪلاسن ۾ داخلا حاصل ڪري سگھان ٿو؟",
    "مانھن EduGainer جي هوم پيج تائين ڪيئن رسائي حاصل ڪري سگھان ٿو؟",
    "مانھن پنهنجي EduGainer اڪائونٽ ۾ ڪيئن لاگ ان ڪري سگھان ٿو؟",
    "EduGainer ويب سائيٽ تي رجسٽريشن جو عمل ڇا آهي؟",
    "جيڪڏهن مان پنھنجو پاسورڊ وساري ويندس ته مونکي ڇا ڪرڻ گهرجي؟",
    "مانھن EduGainer جي مددگار سان چيٽ ڪيئن شروع ڪري سگھان ٿو؟",
    "مانھن EduGainer جي پاليسين کي ڪٿي ڳولي سگھان ٿو؟",
    "EduGainer جي پليٽ فارم تي ڪهڙا وسيلن دستياب آهن؟"
  ],
  ne: [
    "मैले EduGainer को पुस्तकालय खण्डलाई कसरी पहुँच गर्न सक्छु?",
    "मैले पुस्तकालय शुल्क कसरी तिर्न सक्छु?",
    "मैले EduGainer मा कुन कक्षाहरूमा भर्ना गर्न सक्छु?",
    "मैले EduGainer को होमपेजलाई कसरी पहुँच गर्न सक्छु?",
    "मैले आफ्नो EduGainer खाता मा कसरी लग इन गर्न सक्छु?",
    "EduGainer वेबसाइटमा दर्ता प्रक्रिया के हो?",
    "यदि मैले आफ्नो पासवर्ड बिर्से भने म के गर्नुपर्छ?",
    "मैले EduGainer को सहायकसँग कुरा गर्न कसरी सुरु गर्न सक्छु?",
    "मैले EduGainer को नीति कहाँ पाउन सक्छु?",
    "EduGainer को प्लेटफर्ममा के के संसाधनहरू उपलब्ध छन्?"
  ],
  km: [
    "ខ្ញុំធ្វើដូចម្តេចដើម្បីចូលដំណើរការផ្នែកបណ្ណាល័យរបស់ EduGainer?",
    "ខ្ញុំបង់ថ្លៃបណ្ណាល័យដោយរបៀបណា?",
    "ខ្ញុំអាចចុះឈ្មោះក្នុងថ្នាក់ណាខ្លះនៅ EduGainer?",
    "ខ្ញុំចូលដំណើរការទំព័រដើមរបស់ EduGainer ដូចម្តេច?",
    "ខ្ញុំចូលទៅក្នុងគណនី EduGainer របស់ខ្ញុំដូចម្តេច?",
    "ដំណើរការចុះឈ្មោះនៅក្នុងគេហទំព័រ EduGainer គឺជាដូចម្តេច?",
    "ប្រសិនបើខ្ញុំភ្លេចពាក្យសម្ងាត់ខ្ញុំតើខ្ញុំគួរធ្វើម៉េច?",
    "ខ្ញុំអាចចាប់ផ្តើមជជែកជាមួយជំនួយការរបស់ EduGainer ដូចម្តេច?",
    "ខ្ញុំអាចរកឃើញគោលការណ៍របស់ EduGainer នៅឯណា?",
    "ធនធានណាខ្លះនៅលើវេទិការបស់ EduGainer?"
  ],
  ks: [
    "میں EduGainer کے لائبریری سیکشن تک کیسے رسائی حاصل کر سکتا ہوں؟",
    "میں لائبریری فیس کیسے ادا کر سکتا ہوں؟",
    "میں EduGainer میں کس کلاس میں داخلہ لے سکتا ہوں؟",
    "میں EduGainer کی ہوم پیج تک کیسے رسائی حاصل کر سکتا ہوں؟",
    "میں اپنے EduGainer اکاؤنٹ میں کیسے لاگ ان کر سکتا ہوں؟",
    "EduGainer ویب سائٹ پر رجسٹریشن کا عمل کیا ہے؟",
    "اگر میں اپنا پاس ورڈ بھول جاؤں تو مجھے کیا کرنا چاہیے؟",
    "میں EduGainer کے اسسٹنٹ سے چیٹ کیسے شروع کر سکتا ہوں؟",
    "میں EduGainer کی پالیسی کہاں پا سکتا ہوں؟",
    "EduGainer کے پلیٹ فارم پر کون سے وسائل دستیاب ہیں؟"
  ],
  sa: [
    "कस्मे मैं EduGainer के पुस्तकालय खंड तक पहुँच सकता हूँ?",
    "कस्मे मैं पुस्तकालय शुल्क का भुगतान कर सकता हूँ?",
    "कस्मे मैं EduGainer में कौन सी कक्षाओं में नामांकित हो सकता हूँ?",
    "कस्मे मैं EduGainer के होमपेज तक पहुँच सकता हूँ?",
    "कस्मे मैं अपने EduGainer खाते में लॉगिन कर सकता हूँ?",
    "EduGainer वेबसाइट पर पंजीकरण की प्रक्रिया क्या है?",
    "यदि मैं अपना पासवर्ड भूल जाऊँ तो मुझे क्या करना चाहिए?",
    "कस्मे मैं EduGainer के सहायक से चैट शुरू कर सकता हूँ?",
    "कस्मे मैं EduGainer की नीतियाँ कहाँ पा सकता हूँ?",
    "EduGainer के मंच पर कौन से संसाधन उपलब्ध हैं?"
  ],
  z: [
    "ᱟᱢᱩ ᱟᱨᱚ EduGainer ᱪᱷᱟᱨᱤ ᱢᱩᱛᱷᱟᱞᱟ ᱟᱦᱩᱱᱩᱛ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ ᱟᱲᱚ ᱪᱷᱟᱨᱤ ᱠᱷᱟᱹᱥᱤ ᱟᱦᱩᱱᱩᱛ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ EduGainer ᱟᱢᱩᱭ ᱯᱟᱢᱤᱭ ᱡᱚᱱ ᱟᱛᱮᱛᱚᱱ ᱧᱟᱦᱤ?",
    "ᱟᱢᱩ ᱟᱨᱚ EduGainer ᱢᱩᱞᱟ ᱟᱝᱛᱤᱰ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ ᱟᱲᱚ ᱡᱚᱱᱴᱟᱲ ᱟᱦᱩᱱᱩᱛ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ EduGainer ᱨᱮᱥᱤᱪᱷᱟᱞᱟᱦᱮᱱ ᱟᱦᱩᱱᱩᱛ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ ᱞᱟᱛᱷᱟᱜᱤ ᱜᱤ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ ᱟᱲᱚ ᱪᱷᱟᱨᱤ ᱡᱚᱱᱴᱟᱲ ᱧᱟᱦᱤ?",
    "ᱟᱢᱩ ᱟᱨᱚ ᱟᱲᱚ ᱟᱛᱮᱛᱚᱱ ᱪᱷᱟᱨᱤ ᱡᱚᱱ?",
    "ᱟᱢᱩ ᱟᱨᱚ EduGainer ᱤᱨ ᱟᱝᱛᱟᱦᱮ ᱡᱚᱱ ᱤᱛᱟᱲᱟᱦᱮ ᱟᱛᱮᱝᱚᱞᱟᱜᱤ?"
    ],
    bo: [
    "मं EduGainer के लाइब्रेरी सेक्शन तक केंय पहुंच सकता हंव?",
    "मं लाइब्रेरी फीस केंय भरा सकता हंव?",
    "मं EduGainer मं कौन से क्लासेस मं नाम लिखवा सकता हंव?",
    "मं EduGainer के होमपेज तक केंय पहुंच सकता हंव?",
    "मं अपन EduGainer अकाउंट मं केंय लॉगिन कर सकता हंव?",
    "EduGainer वेबसाइट मं रजिस्टर करने के प्रक्रिया का हंव?",
    "अगर मं अपन पासवर्ड भूल जावं, त मं का करू?",
    "मं EduGainer के असिस्टेंट से चैट केंय शुरू कर सकता हंव?",
    "मं EduGainer के पॉलिसीज कहां पा सकता हंव?",
    "EduGainer के प्लेटफॉर्म मं कौन से रिसोर्सेस उपलब्ध हंव?"
  ],
  br: [
    "कैसे हम EduGainer के पुस्तकालय खंड तक पहुँच सकते हैं?",
    "कैसे हम पुस्तकालय शुल्क का भुगतान कर सकते हैं?",
    "हम EduGainer में किस कक्षा में नामांकन कर सकते हैं?",
    "कैसे हम EduGainer की होमपेज तक पहुँच सकते हैं?",
    "कैसे हम अपने EduGainer अकाउंट में लॉग इन कर सकते हैं?",
    "EduGainer वेबसाइट पर पंजीकरण की प्रक्रिया क्या है?",
    "अगर हम अपना पासवर्ड भूल जाएं, तो हमें क्या करना चाहिए?",
    "कैसे हम EduGainer के सहायक से चैट शुरू कर सकते हैं?",
    "हम EduGainer की नीतियाँ कहाँ पा सकते हैं?",
    "EduGainer के प्लेटफॉर्म पर कौन से संसाधन उपलब्ध हैं?"
  ],
  v: [
    "कैसे हम EduGainer के पुस्तकालय खंड तक पहुँच सकते हैं?",
    "कैसे हम पुस्तकालय शुल्क का भुगतान कर सकते हैं?",
    "हम EduGainer में कौन सी कक्षाओं में नामांकन कर सकते हैं?",
    "कैसे हम EduGainer की होमपेज तक पहुँच सकते हैं?",
    "कैसे हम अपने EduGainer अकाउंट में लॉग इन कर सकते हैं?",
    "EduGainer वेबसाइट पर पंजीकरण की प्रक्रिया क्या है?",
    "अगर हम अपना पासवर्ड भूल जाएं, तो हमें क्या करना चाहिए?",
    "कैसे हम EduGainer के सहायक से चैट शुरू कर सकते हैं?",
    "हम EduGainer की नीतियाँ कहाँ पा सकते हैं?",
    "EduGainer के प्लेटफॉर्म पर कौन से संसाधन उपलब्ध हैं?"
  ],
  de: [
    "मं EduGainer के पुस्तकालय खंड तक कइसे पहुँच सकत हंव?",
    "मं पुस्तकालय शुल्क कइसे चुका सकत हंव?",
    "मं EduGainer मं कउन से क्लासेस मं दाखिला ले सकत हंव?",
    "मं EduGainer के होमपेज तक कइसे पहुँच सकत हंव?",
    "मं अपन EduGainer अकाउंट मं कइसे लॉगिन कर सकत हंव?",
    "EduGainer वेबसाइट मं पंजीकरण के प्रक्रिया का हंव?",
    "अगर मं अपन पासवर्ड भूल जाऊं त मं का करूं?",
    "मं EduGainer के असिस्टेंट से चैट कइसे शुरू कर सकत हंव?",
    "मं EduGainer के नीतियाँ कहां पा सकत हंव?",
    "EduGainer के प्लेटफॉर्म मं कउन से रिसोर्स उपलब्ध हंव?"
    ],
    l:["ང EduGainer ᄀᡥᠠᡦ ᡱᠤᡢ᡹ ᡝᡬᡤ ᡥᠠᠴᠧ ᡠᡬᠩ ᡯᡠ᠝?",
    "ང ᠆ᠱᡡᡇᡱ ᡯᠠ ᡻ᠤᡡ᡿ᡨ ᡯᠠᡦᠪᡦ?",
    "ང EduGainer ᠁ᡴᠢᡥᠠᠩᡅ ᠪᡠ᡼ᠳ ᡱᠤᡷ ᡠᡍ ᡄᡓ ᡄᡇ ᠘ᡁᡐᡇ᠄ ᠆᠝",
    "ང EduGainer ᠄ᡁᡔᡠ ᡝᡳᠴᠡ ᠃ᡍ᠂ ᠐ᡴᡆ ᡭᠤ ᡥᡧ",
    "ང ༼ང EduGainer འᡩᡠ᡿ᡀᡦ᠇",
    "EduGainer ࠰ ᠏   %",
    ]
  // You can continue for other languages (like Assamese, Maithili, etc.) following this structure.
};
